import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import API from '@aws-amplify/api';
import { Account, mapToFormData, mapToRequestCreateBody, mapToRequestUpdateBody } from '../models/account';
import { SessionStorageService } from '@eui/core';
import { TranslateService } from '@ngx-translate/core';
import { nvl } from '../../../core/helpers';
import { DeclarationsSearchResult, SearchFilters } from '../../declarations/models';
import { xOperatorId } from '@rtpd/core';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private _api = 'Accounts';
    private _operatorsApi = 'Operators';
    private _declarationsApi = 'Declarations';

    public constructor(
        private storageService: SessionStorageService,
        private translateService: TranslateService,
    ) {
    }

    public get(id: string): Observable<Account> {
        return from(API.get(this._operatorsApi, `/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
        })).pipe(mapToFormData(this.translateService));
    }

    public create(account: Account): Observable<any> {
        return from(API.post(this._api, '', {
            body: mapToRequestCreateBody(account),
        }));
    }

    public update(account: Account): Observable<any> {
        return from(API.put(this._operatorsApi, '', {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
            body: mapToRequestUpdateBody(account),
        }));
    }

    public delete(id: string): Observable<any> {
        return from(API.del(this._operatorsApi, `/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
        }));
    }

    public searchDeclarations(
        filters: SearchFilters,
    ): Observable<DeclarationsSearchResult> {
        return from(
            API.get(this._declarationsApi, '', {
                headers: {
                    [xOperatorId]: nvl(
                        this.storageService.get(xOperatorId),
                    ),
                },
                queryStringParameters: filters,
            }),
        );
    }
}
