import { Component, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rtpd-page-search',
    templateUrl: './page-search.component.html',
    styleUrls: ['./page-search.component.scss'],
})
export class PageSearchComponent implements OnInit, OnDestroy {
    @Input() public isMobile = false;
    @Output() public search = new EventEmitter<string>();

    public searchControl = new FormControl<string | null>(null);
    public foundElements = new Map<HTMLElement, string>(); // element -> original inner HTML
    public highlightedIndex = 0;

    private onDestroy$ = new Subject<void>();

    public ngOnInit() {
        this.subscribeSearchTextChanges();
    }

    public searchDown() {
        if (this.highlightedIndex < this.foundElements.size) {
            this.highlightedIndex++;
        } else {
            this.highlightedIndex = 1;
        }
        this.scrollToElement(this.highlightedIndex - 1);
    }

    public searchUp() {
        if (this.highlightedIndex > 1) {
            this.highlightedIndex--;
        } else {
            this.highlightedIndex = this.foundElements.size;
        }
        this.scrollToElement(this.highlightedIndex - 1);
    }

    private subscribeSearchTextChanges() {
        this.searchControl.valueChanges.pipe(
            debounceTime(200),
            takeUntil(this.onDestroy$),
        ).subscribe((text) => {
            if (text?.length < 3) {
                text = null;
            }
            this.search.emit(text);
            this.searchPage(text);
        });
    }

    private searchPage(text: string) {
        if (this.foundElements.size) {
            this.clearFoundElements();
        }
        if (text?.length) {
            this.findElements(text);
            if (this.foundElements.size) {
                this.highlightElements(text);
                setTimeout(() => this.searchDown(), 200);
            }
        }
    }

    private clearFoundElements() {
        for (const [element, html] of this.foundElements) {
            element.innerHTML = html;
        }
        this.foundElements.clear();
        this.highlightedIndex = 0;
    }

    private highlightElements(text: string) {
        const elements = Array.from(this.foundElements.keys());
        elements.forEach(element => {
            element.innerHTML = element.innerHTML.replace(new RegExp(`(${text})`, 'gi'),
                '<span class="rtpd-page-search-highlight">$1</span>');
        });
    }

    private findElements(text: string) {
        let element: Node;
        const xpath = `//eui-app-page-wrapper//*[text()[contains(translate(normalize-space(.),
            '${text.toUpperCase()}', '${text.toLowerCase()}'),
            '${text.toLowerCase()}')]]`; // XPath 1.0 syntax
        const elements = document?.evaluate(xpath, document, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
        while ((element = elements.iterateNext())) {
            this.foundElements.set(<HTMLElement>element, (<HTMLElement>element).innerHTML);
        }
    }

    private scrollToElement(index: number) {
        const elements = Array.from(this.foundElements.keys());
        elements[index].scrollIntoView({ block: 'center' });
    }

    public ngOnDestroy() {
        this.onDestroy$.next();
    }
}
