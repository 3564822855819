import { Directive, Input, OnChanges, ElementRef } from '@angular/core';
import { EuiCardComponent } from '@eui/components/eui-card';

@Directive({
    selector: 'eui-card[rtpdCardSearchCollapse]',
})
export class CardSearchCollapseDirective implements OnChanges {
    @Input('rtpdCardSearchCollapse') public searchText: string;

    private isInitialCardCollapsed: boolean;

    public constructor(
        private euiCardComponent: EuiCardComponent,
        private elementRef: ElementRef,
    ) {}

    public ngOnChanges(): void {
        // get initial card collapse state
        if (this.isInitialCardCollapsed === undefined) {
            this.isInitialCardCollapsed = this.euiCardComponent.euiCollapsed;
        }

        // if the card is already open, the search will be performed based on the content of the open card
        if (this.searchText?.length && this.euiCardComponent.euiCollapsed === false) {
            return;
        }

        if (this.searchText?.length && this.elementRef?.nativeElement.innerText?.toUpperCase().includes(this.searchText.toUpperCase())) {
            this.euiCardComponent.euiCollapsed = false;
            // do not emit collapse event when opening a card, the search is performed on the content
            // of the closed card and it now opens to show the items found
        } else if (this.isInitialCardCollapsed === true) {
            this.euiCardComponent.euiCollapsed = true;
            // emit a card collapse event so that the card content can be set for page search
            this.euiCardComponent.collapse.emit(true);
        }
    }
}
