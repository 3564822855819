import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { FormGroup } from '@angular/forms';
import { hasInputChanged } from '@rtpd/shared';

@Component({
    selector: 'rtpd-picklist-text',
    templateUrl: './picklist-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickListTextComponent implements OnChanges {
    @Input() public form: FormGroup;
    @Input() public controlName: string;
    @Input() public sourceValues: EuiAutoCompleteItem[];
    @Input() public selectedValues: EuiAutoCompleteItem[];
    @Input() public isRequired = false;
    @Input() public isValuesSeparated = false;
    @Input() public splitSeparator = /[,;]+/; // separator , or ;
    @Input() public isUpperCase = false;

    public ngOnChanges(changes: SimpleChanges) {
        if (hasInputChanged(changes.selectedValues)) {
            this.selectedValues = this.selectedValues.map(val => new EuiAutoCompleteItem(val));
        }
        if (hasInputChanged(changes.sourceValues)) {
            this.sourceValues = this.sourceValues.map(val => new EuiAutoCompleteItem(val));
        }
        if (changes.selectedValues || changes.sourceValues) {
            this.removeSourceValueDuplicates();
        }
        this.setErrors();
    }

    public addSelected(value: { items: EuiAutoCompleteItem[] }) {
        this.form.patchValue({ [this.controlName]: [
            ...this.form.controls[this.controlName].value || [],
            ...value.items,
        ] });
        this.setErrors();
    }

    public removedSelected(value: { items: EuiAutoCompleteItem[] }) {
        this.form.patchValue({ [this.controlName]: [
            ...this.form.controls[this.controlName].value.reduce((values, v) => {
                if(value.items.some(item => item.id === v.id)) {
                    return values;
                }
                return [...values, v];
            }, []),
        ] });
        this.setErrors();
    }

    public removeAllSelected() {
        this.form.patchValue({ [this.controlName]: null });
        this.setErrors();
    }

    public addMoreToSelected(value: string) {
        value = value && value.trim();
        if (value?.length) {
            const splitValues = this.splitNewValuesOnChange(value);
            this.selectedValues = [...this.selectedValues, ...splitValues];
            this.addSelected({ items: splitValues });
        }
    }

    private setErrors() {
        const hasErrors = this.isRequired &&
            (this.form.controls[this.controlName].value == null || this.form.controls[this.controlName].value?.length === 0);
        if (hasErrors) {
            this.form.controls[this.controlName].setErrors({ required: true });
            this.form.controls[this.controlName].markAsTouched();
        } else {
            this.form.controls[this.controlName].setErrors(null);
        }
    }

    private splitNewValuesOnChange(value: string): EuiAutoCompleteItem[] {
        // if required; split values using separator, trim and filter empty
        let values: string[];
        if (this.isValuesSeparated) {
            values = value.split(this.splitSeparator).map(val => val.trim()).filter(String);
        } else {
            values = [value];
        }
        if (this.isUpperCase) {
            values = values.map(val => val.toUpperCase());
        }
        return values.map(val => new EuiAutoCompleteItem({ id: val, label: val }));
    }

    private removeSourceValueDuplicates() {
        this.sourceValues = this.sourceValues?.reduce((values, value) => {
            if (this.selectedValues.some(selected => selected.id === value.id)) {
                return values;
            }
            return [...values, value];
        }, []);
    }
}
