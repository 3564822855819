<div id="history-log-container" class="row">
    <div class="col-12">
        <h3 class="title">{{ "screens.declaration.form.history.fieldset.title" | translate }}</h3>
    </div>

    <ng-container *ngIf="historyLogs.length; else noHistoryLogsFound">
        <div class="eui-u-mb-l">
            <rtpd-time-zone></rtpd-time-zone>
        </div>

        <div class="col-12" *ngFor="let historyLog of historyLogs">
            <ng-container *ngIf="historyLog.changes; else card">
                <eui-card euiCollapsible euiCollapsed>
                    <eui-card-header>
                        <eui-card-header-title>
                            <div class="eui-u-font-size-l">{{historyLogTitle(historyLog)}}</div>
                        </eui-card-header-title>
                    </eui-card-header>
                    <eui-card-content>
                        <ul class="changes-list">
                            <li class="changes-list-item row" *ngFor="let changes of historyLog.changes">
                                <div class="col-sm-12 col-md-4">
                                    <h4>{{ "screens.history.log.changes.field" | translate }}</h4>
                                    <p>{{splitField(changes.field)}}</p>
                                </div>
                                <div class="col-sm-12 col-md-4 rtpd-overflow-wrap">
                                    <h4>{{ "screens.history.log.changes.old.value" | translate }}</h4>
                                    <p *ngIf="isDate(changes.oldValue); else notDateOldValue">{{ changes.oldValue | date: 'dd/MM/yyyy' }}</p>
                                    <ng-template #notDateOldValue><p>{{ changes.oldValue }}</p></ng-template>
                                </div>
                                <div class="col-sm-12 col-md-4 rtpd-overflow-wrap">
                                    <h4>{{ "screens.history.log.changes.new.value" | translate }}</h4>
                                    <p *ngIf="isDate(changes.newValue); else notDateNewValue">{{ changes.newValue | date: 'dd/MM/yyyy' }}</p>
                                    <ng-template #notDateNewValue><p>{{ changes.newValue }}</p></ng-template>
                                </div>

                                <hr class="hr col-12" />

                            </li>
                        </ul>
                    </eui-card-content>
                </eui-card>
            </ng-container>
            <ng-template #card>
                <eui-card class="mb-3">
                    <eui-card-header>
                        <eui-card-header-title>
                            <div class="eui-u-font-size-l">{{historyLogTitle(historyLog)}}</div>
                        </eui-card-header-title>
                    </eui-card-header>
                </eui-card>
            </ng-template>

        </div>
    </ng-container>
    <ng-template #noHistoryLogsFound>
        <p class="message">{{ "screens.history.log.not.found" | translate }}</p>
    </ng-template>

</div>
