import { formatAsAutoCompleteItem } from '@rtpd/core';
import { cleanEmptyString, extractIds } from '@rtpd/shared';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import {
    CREATE_DECLARATIONS,
    UPDATE_DECLARATIONS,
    UPDATE_SUBMITTED_DECLARATIONS,
} from '../actions/declarations.actions';
import { Status } from './status';

export function mapToFormData(translateService: TranslateService): any {
    return map((declaration: Declaration) => {
        const mappedDeclaration = {
            ...declaration,
            declarationPostingCountry:
                formatAsAutoCompleteItem(declaration.declarationPostingCountry, 'screens.common.countries.', translateService),
            declarationOperationType:
                formatAsAutoCompleteItem(declaration.declarationOperationType || [], 'screens.common.operation.type.', translateService),
            declarationTransportType:
                formatAsAutoCompleteItem(declaration.declarationTransportType || [], 'screens.common.transport.type.', translateService),
            declarationVehiclePlateNumber: (declaration.declarationVehiclePlateNumber || [])
                .map(plateNumber => formatAsAutoCompleteItem(plateNumber, null, translateService)),
            driverId: [{
                id: declaration.driverId,
                label: [declaration.driverLatinFirstName, declaration.driverLatinLastName,
                    declaration.driverDateOfBirth ? '(' + declaration.driverDateOfBirth + ')' : ''].join(' ').trim(),
            }],
            driverDocumentType:
                formatAsAutoCompleteItem(declaration.driverDocumentType, 'screens.common.document.type.', translateService),
            driverAddressCountry:
                formatAsAutoCompleteItem(declaration.driverAddressCountry, 'screens.common.countries.', translateService),
            driverApplicableLaw:
                formatAsAutoCompleteItem(declaration.driverApplicableLaw, 'screens.common.countries.', translateService),
            driverDocumentIssuingCountry:
                formatAsAutoCompleteItem(declaration.driverDocumentIssuingCountry, 'screens.common.countries.', translateService),
            operatorCountryOfRegistration:
                formatAsAutoCompleteItem(declaration.operatorCountryOfRegistration, 'screens.common.countries.', translateService),
            operatorAddressCountry:
                formatAsAutoCompleteItem(declaration.operatorAddressCountry, 'screens.common.countries.', translateService),
            transportManagerLicenseCountry:
                formatAsAutoCompleteItem(declaration.transportManagerLicenseCountry, 'screens.common.countries.', translateService),
            transportManagerAddressCountry:
                formatAsAutoCompleteItem(declaration.transportManagerAddressCountry, 'screens.common.countries.', translateService),
            otherContactAsTransportManager: declaration.otherContactAsTransportManager == null ?
                false : declaration.otherContactAsTransportManager,
            otherContactAddressCountry:
                formatAsAutoCompleteItem(declaration.otherContactAddressCountry, 'screens.common.countries.', translateService),
        };

        return cleanEmptyString(mappedDeclaration);
    });
}

export function mapToRequestBody(declaration: Declaration, action: string): any {

    switch (action) {
        case UPDATE_DECLARATIONS:
            return {
                declarationPostingCountry: extractIds(declaration.declarationPostingCountry),
                declarationStartDate: declaration.declarationStartDate,
                declarationEndDate: declaration.declarationEndDate,
                declarationOperationType: extractIds(declaration.declarationOperationType),
                declarationTransportType: extractIds(declaration.declarationTransportType),
                declarationVehiclePlateNumber: extractIds(declaration.declarationVehiclePlateNumber),
                driverId: declaration.driverId?.[0],
                driverEmail: declaration.driverEmail,
                otherContactAsTransportManager: declaration.otherContactAsTransportManager,
                otherContactFirstName: declaration.otherContactFirstName,
                otherContactLastName: declaration.otherContactLastName,
                otherContactEmail: declaration.otherContactEmail,
                otherContactPhone: declaration.otherContactPhone,
                otherContactAddressStreet: declaration.otherContactAddressStreet,
                otherContactAddressPostCode: declaration.otherContactAddressPostCode,
                otherContactAddressCity: declaration.otherContactAddressCity,
                otherContactAddressCountry: extractIds(declaration.otherContactAddressCountry),
            };
        case UPDATE_SUBMITTED_DECLARATIONS:
            return {
                declarationEndDate: declaration.declarationEndDate,
                declarationOperationType: extractIds(declaration.declarationOperationType),
                declarationTransportType: extractIds(declaration.declarationTransportType),
                declarationVehiclePlateNumber: extractIds(declaration.declarationVehiclePlateNumber),
                driverEmail: declaration.driverEmail,
                otherContactAsTransportManager: declaration.otherContactAsTransportManager,
                otherContactFirstName: declaration.otherContactFirstName,
                otherContactLastName: declaration.otherContactLastName,
                otherContactEmail: declaration.otherContactEmail,
                otherContactPhone: declaration.otherContactPhone,
                otherContactAddressStreet: declaration.otherContactAddressStreet,
                otherContactAddressPostCode: declaration.otherContactAddressPostCode,
                otherContactAddressCity: declaration.otherContactAddressCity,
                otherContactAddressCountry: extractIds(declaration.otherContactAddressCountry),
            };
        case CREATE_DECLARATIONS:
            return {
                declarationPostingCountry: extractIds(declaration.declarationPostingCountry),
                declarationPostingCountries: extractIds(declaration.declarationPostingCountries),
                declarationStatus: declaration.declarationStatus,
                declarationStartDate: declaration.declarationStartDate,
                declarationEndDate: declaration.declarationEndDate,
                declarationOperationType: extractIds(declaration.declarationOperationType),
                declarationTransportType: extractIds(declaration.declarationTransportType),
                declarationVehiclePlateNumber: extractIds(declaration.declarationVehiclePlateNumber),
                driverId: declaration.driverId?.[0],
                driverEmail: declaration.driverEmail,
                otherContactAsTransportManager: declaration.otherContactAsTransportManager,
                otherContactFirstName: declaration.otherContactFirstName,
                otherContactLastName: declaration.otherContactLastName,
                otherContactEmail: declaration.otherContactEmail,
                otherContactPhone: declaration.otherContactPhone,
                otherContactAddressStreet: declaration.otherContactAddressStreet,
                otherContactAddressPostCode: declaration.otherContactAddressPostCode,
                otherContactAddressCity: declaration.otherContactAddressCity,
                otherContactAddressCountry: extractIds(declaration.otherContactAddressCountry),
            };
    }
    return {};
}

export interface Declaration {
    declarationId?: string;
    declarationPostingCountry?: any;
    declarationPostingCountries?: string[];
    declarationStartDate: string;
    declarationEndDate: string;
    declarationOperationType: any[];
    declarationTransportType: any[];
    declarationStatus: Status;
    declarationEmailStatus?: string;
    declarationVehiclePlateNumber: any[];
    declarationQrCode?: string;
    driverId: any;
    driverLatinFirstName?: string;
    driverLatinLastName?: string;
    driverLatinFullName?: string;
    driverNonLatinFirstName?: string;
    driverNonLatinLastName?: string;
    driverNonLatinFullName?: string;
    driverEmail?: string;
    driverDateOfBirth?: string;
    driverLicenseNumber?: string;
    driverDocumentType?: any;
    driverDocumentNumber?: string;
    driverDocumentIssueDate?: string;
    driverDocumentExpirationDate?: string;
    driverAddress?: string;
    driverAddressPostCode?: string;
    driverAddressCity?: string;
    driverAddressCountry?: any;
    driverContractStartDate?: string;
    driverApplicableLaw?: any;
    driverDocumentIssuingCountry: any;
    driverCardNumber:string;
    operatorId: string;
    operatorName: string;
    operatorLicenseNumber: string;
    operatorRegisterNumber: string;
    operatorVat: string;
    operatorCountryOfRegistration: string;
    operatorEmail: string;
    operatorAddressStreet: string;
    operatorAddressPostCode: string;
    operatorAddressCity: string;
    operatorAddressCountry: string;
    transportManagerEmail: string;
    transportManagerFirstName: string;
    transportManagerLastName: string;
    transportManagerPhone: string;
    transportManagerLicenseNumber: string;
    transportManagerLicenseCountry: string;
    transportManagerAddressStreet: string;
    transportManagerAddressPostCode: string;
    transportManagerAddressCity: string;
    transportManagerAddressCountry: string;
    otherContactAsTransportManager: boolean;
    otherContactFirstName?: string;
    otherContactLastName?: string;
    otherContactEmail?: string;
    otherContactPhone?: string;
    otherContactAddressStreet?: string;
    otherContactAddressPostCode?: string;
    otherContactAddressCity?: string;
    otherContactAddressCountry?: string;
    declarationCreatedOn?: string;
    declarationModifiedOn?: string;
    declarationSubmissionDate?: string;
    declarationWithdrawalDate?: string;
}

