import { isEmpty } from '../../core/helpers';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { FormControl } from '@angular/forms';

export function nullToEmptyStr(model) {
    Object.keys(model).forEach(k => model[k] = (model[k] === null) ? '' : model[k]);
    return model;
}

export const currentYear: number = new Date().getFullYear();

export function formatDate(date: number | string | Date): string {
    if (!date) {
        return null;
    }
    if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
    }

    return date.toISOString().substring(0, 10);
}

export function addMonthsToDate(textDate: string, addMonths: number): string {

    if (!textDate) {
        return null;
    }

    const splitDate = textDate.split('-');
    const fromYear = Number(splitDate[0]);
    const fromMonth = Number(splitDate[1]);
    const fromDay = Number(splitDate[2]);

    let toMonth = ((fromMonth + addMonths - 1) % 12) + 1;
    let toYear = (toMonth < fromMonth) ? fromYear + 1 : fromYear;
    let toDay = Math.min(fromDay, getDaysInMonth(toYear, toMonth));

    // exclude boundary
    if (toDay == fromDay) {
        if (toDay == 1) {
            if (toMonth == 1) {
                toYear = toYear - 1;
                toMonth = 12;
            } else {
                toMonth = toMonth - 1;
            }
            toDay = getDaysInMonth(toYear, toMonth);
        } else {
            toDay = toDay - 1;
        }
    }
    return `${toYear}-${printLeading0(toMonth)}-${printLeading0(toDay)}`;
}

function printLeading0(num: number): string {
    if (num > 0 && num < 10) {
        return `0${num}`;
    } else {
        return num.toString();
    }
}

function isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

/**
 * days in a month, month is 1-12
 * @param year
 * @param month
 */
function getDaysInMonth(year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
}

export function extractIds(object: any): string | string[] {
    if (isEmpty(object, true)) {
        return null;
    }
    if (Array.isArray(object)) {
        return object.filter(item => {
            if (Object.keys(item).includes('id')) {
                return !isEmpty(item.id);
            } else if (Object.keys(item).includes('code')) {
                return !isEmpty(item.code);
            } else if (typeof item === 'string') {
                return !isEmpty(item);
            }
            return false;
        }).map(item => item.id || item.code || item);
    }
    if (Object.keys(object).includes('id')) {
        return object.id;
    } else if (Object.keys(object).includes('code')) {
        return object.code;
    } else if (typeof object === 'string') {
        return object;
    }
    return null;
}

export function* IDGenerator(start: number): IterableIterator<number> {
    while (true) {
        yield start++;
    }
}

/**
 * common function to check if new @Input() arrived in components.
 * @changesProperty a property of changes parameter - ngOnChanges(changes: SimpleChanges)
 */
export function hasInputChanged(changesProperty: any, allowEmptyString = false): boolean {
    if (!changesProperty) {
        return false;
    }
    return !areDeepEqual(changesProperty?.currentValue, changesProperty?.previousValue, allowEmptyString);
}

export function hasRefDataChanged(changesProperty: any): boolean {
    return changesProperty?.currentValue !== undefined && changesProperty?.currentValue !== null &&
        !areDeepEqual(changesProperty?.currentValue, changesProperty?.previousValue);
}

export function areDeepEqual(first, second, allowEmptyString = false) {
    const isFirstEmpty = isEmpty(first, allowEmptyString);
    const isSecondEmpty = isEmpty(second, allowEmptyString);

    if (isFirstEmpty && isSecondEmpty) {
        return true;
    }

    if (isFirstEmpty || isSecondEmpty) {
        return false;
    }

    const firstTypeof = typeof first;
    const secondTypeof = typeof second;

    if (firstTypeof !== secondTypeof) {
        return false;
    }

    if (firstTypeof === 'string' || firstTypeof === 'boolean') {
        return first === second;
    }

    if (isObject(first)) {
        if (isArray(first) && isArray(second)) {
            return areArraysDeepEqual(first, second);
        }

        return areObjectsDeepEqual(first, second);
    }

    return true;
}

function areArraysDeepEqual(firstArray, secondArray) {
    const filteredFirstArray = firstArray.filter(item => !isEmpty(item));
    const filteredSecondArray = secondArray.filter(item => !isEmpty(item));

    if (filteredFirstArray.length !== filteredSecondArray.length || filteredFirstArray.length === 0) {
        return false;
    }

    return filteredFirstArray.every(fItem => filteredSecondArray.some(sItem => areDeepEqual(fItem, sItem)));
}

function areObjectsDeepEqual(firstObject, secondObject) {
    const keysFirst = Object.keys(firstObject);
    const keysSecond = Object.keys(secondObject);

    if (keysFirst.length !== keysSecond.length) {
        return false;
    }

    return keysFirst.every(key => {
        const valFirst = firstObject[key];
        const valSecond = secondObject[key];
        const areObjects = isObject(valFirst) && isObject(valSecond);

        return (areObjects && areDeepEqual(valFirst, valSecond)) ||
            (!areObjects && valFirst === valSecond);
    });
}

export function isObject(object) {
    return object && typeof object === 'object';
}

export function isArray(object) {
    return isObject(object) && Array.isArray(object);
}

export function getId(country: EuiAutoCompleteItem) {
    return country ? country.id : null;
}

export function isProduction(name) {
    return 'production' === name;
}

export function isTraining(name) {
    return 'training' === name;
}

export function combineSearch(search, lastEvaluatedKey) {
    return isEmpty(lastEvaluatedKey)
        ?
        search
        : {
            ...search,
            filters: {
                ...search.filters,
                startKey: lastEvaluatedKey,
            },
        };
}

export const extendedCountryList = ['XK'];

export const SHOW_MORE_ROWS = '250';

export const SCROLL_HEIGHT = '515px';

export function getDisplayCount(count: number, lastEvaluatedKey: string): string {
    let displayCount = null;

    if (count != null) {
        displayCount = isEmpty(lastEvaluatedKey) ? count.toString() : count.toString() + '+';
    }

    return displayCount;
}

export function convertToMultiSortMeta(fieldSortOrderMap) {

    const multiSortMeta = [];

    for (const [field, order] of Object.entries(fieldSortOrderMap)) {
        multiSortMeta.push({ field: field, order: order });
    }

    return multiSortMeta.length > 0 ? multiSortMeta : [{}];
}

export function isBannerVisible(messageKey, translateService, euConfig) {
    const message: string = translateService.instant(messageKey);
    return isProduction(euConfig.environment.name)
        && message != messageKey
        && message != '';
}

export function formValueChanged(oldValue, newValue) {
    return (oldValue?.length > 0 && !newValue?.length)
        || (newValue?.length > 0 && !oldValue?.length)
        || oldValue !== newValue;
}

export function formAutocompleteArrayChanged(oldArray, newArray) {
    if (oldArray == undefined && newArray == undefined) {
        return false;
    }
    const arrayChanged = (oldArray == undefined && newArray != undefined)
        || (oldArray != undefined && newArray == undefined)
        || (oldArray.length !== newArray.length)
        || elementsChanged(oldArray, newArray);
    return arrayChanged;
}

export function elementsChanged(oldArray: EuiAutoCompleteItem[], newArray: EuiAutoCompleteItem[]) {
    const itemsSet = new Set(oldArray.map((item: EuiAutoCompleteItem) => {
        return item.id;
    }));
    return !newArray.every((item: EuiAutoCompleteItem) => itemsSet.has(item.id));
}

export function formAutocompleteValueChanged(oldValue: EuiAutoCompleteItem, newValue: EuiAutoCompleteItem) {
    let autocompleteChanged = false;
    if (oldValue != undefined || newValue != undefined) {
        autocompleteChanged = oldValue == undefined || newValue == undefined || (oldValue.id !== newValue.id);
    }
    return autocompleteChanged;
}

export function reselectFormControlValue(formControl: FormControl, items: EuiAutoCompleteItem[]): void {
    const previousValues = getPreviousValues(formControl);

    if (previousValues && previousValues.length > 0) {
        const selectedItems = items.filter(item => previousValues.includes(item.id));
        if (selectedItems.length > 0) {
            setValue(formControl, selectedItems);
        }
    }
}

function getPreviousValues(formControl: FormControl): any[] {
    if (!formControl.value) {
        return [];
    }

    if (Array.isArray(formControl.value)) {
        return formControl.value.map(item => item.id);
    } else {
        return [formControl.value.id];
    }
}

function setValue(formControl: FormControl, value: any): void {
    if (Array.isArray(formControl.value)) {
        formControl.setValue(value);
    } else {
        formControl.setValue(value.length == 1 ? value[0] : null);
    }
}

export function cleanEmptyString(object: any): any {
    return JSON.parse(
        JSON.stringify(
            object || {},
            (key, value) => value === '' ? null : value,
        ),
    );
}

export function clean(object: any, cleanEmptyString = false): any {
    if (object == null || Object.keys(object).length === 0) {
        return {};
    }

    // Perform the cleaning logic
    return JSON.parse(
        JSON.stringify(
            object || {},
            (key, value) => {
                // Remove key-value pairs where the value is null, undefined, or an empty object
                if (value == null || (typeof value === 'object' && Object.keys(value).length === 0)) {
                    return undefined;
                }
                if (cleanEmptyString && value === '') {
                    return undefined;
                }
                return value;
            },
        ),
    );
}

export function emptyToNull(property) {
    return isEmpty(property) ? null : property;
}
