import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

import { Operator } from '../../../shared/models/operators';
import { formatAsAutoCompleteItem } from '@rtpd/core';
import { getId, extractIds, emptyToNull, SystemUser } from '@rtpd/shared';

export function mapToFormData(translateService: TranslateService): OperatorFunction<Operator, Account> {
    return map((operator: Operator) => {
        return new Account({
            operatorId: operator.operatorId,
            operatorName: operator.operatorName,
            operatorLicenseNumber: operator.operatorLicenseNumber,
            operatorRegisterNumber: operator.operatorRegisterNumber,
            operatorVat: operator.operatorVat,
            operatorCountryOfRegistration:
                formatAsAutoCompleteItem(operator.operatorCountryOfRegistration, 'screens.common.countries.', translateService),

            operatorEmail: operator.operatorEmail,
            operatorVehiclePlateNumber: (operator.operatorVehiclePlateNumber || [])
                .map(plateNumber => formatAsAutoCompleteItem(plateNumber, null, translateService)),
            operatorAddressStreet: operator.operatorAddressStreet,
            operatorAddressPostCode: operator.operatorAddressPostCode,
            operatorAddressCity: operator.operatorAddressCity,
            operatorAddressCountry:
                formatAsAutoCompleteItem(operator.operatorAddressCountry, 'screens.common.countries.', translateService),

            transportManagerEmail: operator.transportManagerEmail,
            transportManagerFirstName: operator.transportManagerFirstName,
            transportManagerLastName: operator.transportManagerLastName,
            transportManagerPhone: operator.transportManagerPhone,
            transportManagerLicenseNumber: operator.transportManagerLicenseNumber,
            transportManagerLicenseCountry:
                formatAsAutoCompleteItem(operator.transportManagerLicenseCountry, 'screens.common.countries.', translateService),

            transportManagerAddressStreet: operator.transportManagerAddressStreet,
            transportManagerAddressPostCode: operator.transportManagerAddressPostCode,
            transportManagerAddressCity: operator.transportManagerAddressCity,
            transportManagerAddressCountry:
                formatAsAutoCompleteItem(operator.transportManagerAddressCountry, 'screens.common.countries.', translateService),

        });
    });
}

export function mapFromUser(user: SystemUser): Account {
    return new Account({
        userId: user.userId,
        userEmail: user.email,
        userFirstName: user?.firstName,
        userLastName: user?.lastName,
        userPhone: user?.phone,
        operatorCount: user.operatorDetails?.length,
    });
}

export function mapToRequestCreateBody(account: Account): any {
    return {
        ...account,
        operatorCountryOfRegistration: getId(account.operatorCountryOfRegistration),
        operatorAddressCountry: getId(account.operatorAddressCountry),
        transportManagerLicenseCountry: getId(account.transportManagerLicenseCountry),
        transportManagerAddressCountry: getId(account.transportManagerAddressCountry),
    };
}

export function mapToRequestUpdateBody(account: Account): any {
    return {
        ...mapToRequestCreateBody(account),
        operatorVehiclePlateNumber: emptyToNull(extractIds(account.operatorVehiclePlateNumber)),
    };
}

export class Account {
    public userId?: string;
    public userEmail?: string;
    public userFirstName?: string;
    public userLastName?: string;
    public userPhone?: string;

    public operatorId: string;
    public operatorName: string;
    public operatorEmail: string;
    public operatorVehiclePlateNumber?: string[];
    public operatorLicenseNumber: string;
    public operatorRegisterNumber: string;
    public operatorVat: string;
    public operatorCountryOfRegistration: EuiAutoCompleteItem;

    public operatorAddressStreet: string;
    public operatorAddressPostCode: string;
    public operatorAddressCity: string;
    public operatorAddressCountry: EuiAutoCompleteItem;

    public transportManagerEmail: string;
    public transportManagerFirstName: string;
    public transportManagerLastName: string;
    public transportManagerPhone: string;
    public transportManagerLicenseNumber: string;
    public transportManagerLicenseCountry: EuiAutoCompleteItem;

    public transportManagerAddressStreet: string;
    public transportManagerAddressPostCode: string;
    public transportManagerAddressCity: string;
    public transportManagerAddressCountry: EuiAutoCompleteItem;

    public operatorCount: number;

    public createdBy?: string;
    public modifiedOn?: Date;
    public createdOn?: Date;

    public constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
